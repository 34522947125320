<template>
  <b-container fluid>
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <div class="iq-card p-4">
          <div class="content">
            <div :class="status === 'Failed' ? 'bg-orange pay_img' :'bg-green pay_img'">
            </div>
              <h1>Payment {{status}} !</h1>
              <p v-if="redirectPath">Please wait while we are redirecting ...<br>
                  <button v-if="cvLoadingStatus" type="button" class="btn primary" style="color: var(--iq-primary);" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
              </p>
              <p v-if="!type">Thank you for completing the payment. Your invoice will be emailed to you shortly.</p>
              <p v-if="type === 'univ'">
                Thank you for completing the transaction. Your payment will be verified soon and your plan will be activated shortly.
              </p>
              <div class="row box_1">
               <div class="col-6">
                <a :href="redirectPath" class="back_button"> <i class="fa fa-chevron-left pr-1" aria-hidden="true"></i> Go Back</a>
               </div>
                <div class="col-6">
                  <a :href="`/my_billing_history/${userData.user_id}`" class="bg-gide-red float-right history">Billing History</a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "Paygw_status",
  data () {
    return {
      status: "Success",
      redirectPath: "#",
      type: null,
      cvLoadingStatus: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  async mounted () {
    socialvue.loaderInit()
    this.status = this.$route.query.status
    this.type = this.$route.query.type

    // Save the URL to be redirected to after payment is successful
    this.redirectPath = this.redirectPath ? this.redirectPath : "home"
    this.redirectPath = this.$route.hash ? `${this.$route.query.redirect}/${this.$route.hash}` : this.$route.query.redirect
    if (this.redirectPath) {
      setTimeout(() => {
        this.$router.push(`/${this.redirectPath}`)
      }, 8000)
    }

    if (this.status === "Captured") {
      this.status = "Processed"
    }

    await this.$store.commit("Paygw/addSelectedPlanCommit", {})
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.content h1{
  font-weight: 600;
  font-size: 37px;
  text-align: center;
}
.bg-gide-red {
  background: #e5252c;
  color: #fff !important;
}
.back_button{
  border: 2px solid;
  width: 110px;
  display: block;
  text-align: center;
  border-radius: 25px;
}
.history{
  background-color: transparent;
  border: 2px solid ;
  width: 160px;
  text-align: center;
  color: #e5252c !important;
  border-radius: 25px;
}
.box_1{
  margin-top: 50px;
}
p{
  text-align: center;
  color: var(--iq-title-text);
  font-size: 14px;
  font-weight: 500;
}
.container-fluid{
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: max-content;
}
.pay_img{
  width: 200px;
  margin: auto;
  display: block;
  height: 200px;
}
.bg-orange{
  background-image: url("../../../assets_gide/img/payment/Payment-Unsuccessful.png");
  background-repeat: no-repeat;
  background-size: 201px 200px;
}
.bg-green{
  background-image: url("../../../assets_gide/img/payment/Payment-Successful.png");
  background-repeat: no-repeat;
  background-size: 201px 200px;
}
@media screen and (min-width: 1200px) {
  .container-fluid{
    padding-left: 230px !important;
  }
}
</style>
